.presence-cursor {

  --tw-drop-shadow: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));

  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);

  position: absolute;
  pointer-events: none;
  z-index: 9999;

  --fill: color-mix(in hsl, var(--color), black 25%);
  --fill: color-mix(in oklch, var(--color), black 25%)
}

.presence-cursor-own {

  outline-style: solid;

  outline-width: 1px;

  outline-color: #fff
}

.presence-cursor-cta,
.presence-cursor-chat {

  min-width: 1.5rem;

  border-radius: 0.25rem;

  padding-left: 0.5rem;

  padding-right: 0.5rem;

  padding-top: 0.25rem;

  padding-bottom: 0.25rem;

  font-size: 1rem;

  line-height: 1.5rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));

  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);

  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: var(--fill);
  white-space: nowrap;
  overflow: hidden
}

::highlight(highlight-party) {

  background-color: hsl(from var(--accent) h s l/0.2)
}
