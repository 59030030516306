.cursors-root {
  overflow: hidden;
  isolation: isolate;
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.presence-cursor {
  filter: drop-shadow(var(--drop-shadow-lg));
  position: absolute !important;
  pointer-events: none;
  z-index: 9999;

  --fill: color-mix(in hsl, var(--color), black 25%);
  --fill: color-mix(in oklch, var(--color), black 25%);
}

.presence-cursor-own {
  outline: solid 1px var(--color-white);
}

.presence-cursor-cta,
.presence-cursor-chat {
  color: var(--color-white);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
  min-width: 32px;
  box-shadow: var(--shadow-2xl);
  background-color: var(--fill);
  white-space: nowrap;
  overflow: hidden;
}

::highlight(highlight-party) {
  background-color: hsl(from var(--accent) h s l / 0.2);
}
